import 'slick-carousel/slick/slick';

$('.post-object-slider').slick({
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
});
